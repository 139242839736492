import { getMenus, matchPath } from '@pn/components/src/utils'
import { Breadcrumb, Layout, Menu } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import useAppContext from '@/useAppContext'

import LayoutHeader from './LayoutHeader'
import styles from './styles.module.less'

const { Content, Sider } = Layout

const RootLayout = () => {
    const { t } = useTranslation()
    const { context } = useAppContext.getContext()
    const { isLogin, menus, user } = context
    const navgate = useNavigate()
    const location = useLocation()
    const [collapsed, setCollapsed] = useState(false)
    if (!isLogin) {
        return <Navigate replace to={{ pathname: '/login' }} />
    }
    const handleClick = (e) => {
        navgate(e.key)
    }
    let menuItems = getMenus(menus, t)

    const globalBranchRoutes = matchPath(menus, location.pathname)
    const route = globalBranchRoutes.filter((d) => !d.route.hideInMenu).map((d) => d.match.path)
    let selectedKeys = route.slice(-1)

    const breadcrumbs = globalBranchRoutes
        .filter((d) => !d.route.hideInBreadcrumb)
        .reduce((obj, item) => {
            if (obj[item.match.path]) {
                return obj
            }
            return { ...obj, [item.match.path]: item }
        }, {})

    const breadcrumbRoutes = Object.values(breadcrumbs)
    return (
        <Layout className={styles.app}>
            <Sider width={200} className={styles.sider} collapsed={collapsed}>
                <div className={styles.logo}>
                    {/* <img className='logo-img' src={logo} /> */}
                    {!collapsed && <div className={styles.logoname}>{t('思铭客户')}</div>}
                </div>
                <Menu
                    onClick={handleClick}
                    className={styles.menu}
                    mode="inline"
                    theme="dark"
                    defaultOpenKeys={route}
                    selectedKeys={selectedKeys}
                    items={menuItems}
                />
            </Sider>
            <Layout>
                <LayoutHeader collapsed={collapsed} setCollapsed={setCollapsed} user={user} />
                {breadcrumbRoutes.length > 1 && (
                    <Breadcrumb className={styles.breadcrumb}>
                        {breadcrumbRoutes.map((d: any) => {
                            return (
                                <Breadcrumb.Item key={d.match.path}>
                                    {d.match.path == location.pathname ? (
                                        t(d.route.name)
                                    ) : (
                                        <Link to={d.match.path}>{t(d.route.name)}</Link>
                                    )}
                                </Breadcrumb.Item>
                            )
                        })}
                    </Breadcrumb>
                )}
                <Content className={styles.layoutmain}>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    )
}

export default RootLayout
