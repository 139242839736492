import { defaultLanguage, locales } from '@pn/components/src/locale'
import { useData } from '@pn/components/src/utils/hooks'
import { keepPreviousData, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom/client'
import { RecoilRoot } from 'recoil'

import useAppContext from '@/useAppContext'

import App from './App'

import './main.less'

window.tokenKey = 'token'
window.clientId = 'e5cd7e4891bf95d1d19206ce24a7b32e'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            gcTime: 1000 * 60, // 数据垃圾回收时间 默认 1000 * 60 * 5 5分钟
            staleTime: 100 * 1, // 重新获取数据的时间间隔 默认0
            retry: 2, // 失败重试次数 默认 3次
            refetchOnWindowFocus: false, // 窗口获得焦点时重新获取数据
            placeholderData: keepPreviousData,
            // keepPreviousData: false,
            //   retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
        },
    },
})

const Root = () => {
    const initValue = {
        token: sessionStorage.getItem(window.tokenKey) || localStorage.getItem(window.tokenKey),
        user: {},
        isAdmin: false,
        menus: [],
        isLogin: false,
        loading: true,
        language: defaultLanguage,
    }
    const dataRef = useRef<any>({})
    // useNotification(1)
    const [data, setData] = useData(initValue)
    dataRef.current = data
    let context = { context: data, dispatch: setData }
    useEffect(() => {
        dayjs.locale(data.language)
    }, [data.language])
    return (
        <RecoilRoot>
            <ConfigProvider locale={locales[data.language]}>
                <QueryClientProvider client={queryClient}>
                    <useAppContext.Provider value={context}>
                        <App />
                    </useAppContext.Provider>
                </QueryClientProvider>
            </ConfigProvider>
        </RecoilRoot>
    )
}

// @ts-ignore
ReactDOM.createRoot(document.getElementById('root')!).render(<Root />)
