import { importCompont } from '@pn/components/src/utils'
import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import Layout from './Layout'
import Login from './Login'

const Register = importCompont(() => import('./Login/Register'))
const Repassword = importCompont(() => import('./Login/Repassword'))

const Router = ({ routes }) => {
    const router = useRoutes([
        { path: '/login', element: <Login /> },
        { path: '/register', element: <Register /> },
        { path: '/repassword', element: <Repassword /> },
        {
            path: '/',
            element: <Layout />,
            children: routes,
        },
        { path: '*', element: <Navigate replace to={{ pathname: '/' }} /> },
    ])
    return router
}

export default Router
