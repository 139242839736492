import { initCycleMenu } from '@pn/components/src/utils'
import { useMutation } from '@tanstack/react-query'
import { message } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HashRouter } from 'react-router-dom'

import { getUserInfo } from '@/services'
import useAppContext from '@/useAppContext'

import Router from './pages/Router'
import { routes } from './pages/routes'

const App = () => {
    const { t } = useTranslation()
    const { context, dispatch } = useAppContext.getContext()
    const { token, language, loading, menus = [] } = context
    useEffect(() => {
        document.title = t('思铭金融客户管理系统')
    }, [language])

    const mutation = useMutation({
        mutationFn: () => getUserInfo(),
        onSuccess: (data: any) => {
            const isAdmin = data.permissions.includes('*:*:*')
            let menus = initCycleMenu(routes, data.permissions, isAdmin)
            dispatch({ user: data.user, loading: false, isLogin: true, menus, isAdmin })
        },
        onError: (msg: string) => {
            localStorage.removeItem(window.tokenKey)
            sessionStorage.removeItem(window.tokenKey)
            dispatch({ token: undefined, loading: false, isLogin: false })
        },
    })
    useEffect(() => {
        if (!token) {
            return dispatch({ loading: false, isLogin: false })
        }
        // let menus = initCycleMenu(routes, ["XTGL2", "XTGL", "XTGL-JSGL"], true)
        // dispatch({ user: {}, loading: false, isLogin: true, menus })
        dispatch({ loading: true, isLogin: false })
        mutation.mutate()
        let out = false
        const logOut = () => {
            if (!out) {
                message.warning(t('token无效或已过期，请重新登录'))
            }
            out = true
            localStorage.removeItem(window.tokenKey)
            sessionStorage.removeItem(window.tokenKey)
            dispatch({ token: undefined, loading: false, isLogin: false })
        }
        window.addEventListener('tokenOut', logOut, false)
        return () => window.removeEventListener('tokenOut', logOut, false)
    }, [token])
    if (loading) {
        return null
    }
    return (
        <HashRouter>
            <Router routes={menus} />
        </HashRouter>
    )
}

export default App
