import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Layout, Menu, Space } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import useAppContext from '@/useAppContext'

import styles from './styles.module.less'

const { Header } = Layout

const LayoutHeader = ({ collapsed, setCollapsed, user }) => {
    const { dispatch } = useAppContext.getContext()
    const { t } = useTranslation()
    const items = [
        // { key: 'ucenter', label: "个人中心" },
        { key: 'logout', label: t('退出登录') },
    ]

    const handleClick = (e) => {
        if (e.key == 'logout') {
            sessionStorage.removeItem(window.tokenKey)
            localStorage.removeItem(window.tokenKey)
            dispatch({ token: undefined, isLogin: false })
        }
    }

    const menu = <Menu items={items} onClick={handleClick} />

    return (
        <Header className={styles.header}>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: styles.trigger,
                onClick: () => setCollapsed(!collapsed),
            })}
            <Space>
                <Dropdown overlay={menu}>
                    <div className={styles.userinfo}>
                        <Avatar size={24} icon={<UserOutlined />} />
                        <a className={styles.username}>{user.nickName || user.email || 'Admin'}</a>
                    </div>
                </Dropdown>
            </Space>
        </Header>
    )
}

export default LayoutHeader
