import axios from "axios";
import qs from 'qs';

const langs = {
    "zh-CN": 'zh',
    "en-US": 'en',
    "fr_FR": 'fr'
}

const tr = (str) => langs[str] || str

const { baseUrl } = window.AppConfig
const netfetch = axios.create({
    baseURL: baseUrl,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
    }
});

// 添加请求拦截器
netfetch.interceptors.request.use((config) => {
    // @ts-ignore
    config.headers = {
        Authorization: sessionStorage.getItem(window.tokenKey) || localStorage.getItem(window.tokenKey),
        language: tr(localStorage.getItem("language") || "zh-CN"),
        "clientId": window.clientId,
        ...config.headers
    };
    // @ts-ignore
    config.paramsSerializer = params => {
        return qs.stringify(params, { indices: false })
    }
    return config;
}, (error) => {
    console.log("before", error)
    throw error
});

// 添加响应拦截器
netfetch.interceptors.response.use((response) => {
    if (response.request.responseType == "arraybuffer") {
        return response.data;
    }
    const { code, msg, ...rest } = response.data
    if (response.data.code == 200) {
        if (Object.keys(rest).length >= 2) {
            return rest
        }
        return response.data.data;
    }
    if ([401].includes(response.data.code)) {
        const event = new CustomEvent("tokenOut")
        window.dispatchEvent(event);
    }
    throw response.data.msg || response.data.message
}, (error) => {
    throw error.message || error
});

export default netfetch;
